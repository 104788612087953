@import "@/styles/mixins";

h1,
.h1 {
  font-family: "SofiaPro", sans-serif;
  font-size: 1.875rem;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 2px;
  padding-bottom: 8px;
  white-space: pre-wrap; // allow newlines
}

h2,
.h2 {
  font-family: "SofiaPro", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 1.2em;
  letter-spacing: 1px;
  white-space: pre-wrap; // allow newlines
}

.subHeader {
  font-family: "Libre Franklin";
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
}

.label {
  font-family: "SofiaPro", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
}

a,
.link {
  font-family: "Libre Franklin";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
}

.linkBigOnDesktop {
  font-family: "Libre Franklin";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.125em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
  // TODO: padding and display properties should not be here (should be in navbar and footer)
  padding: 0.75rem 0.25rem 0.75rem 0px;
  display: inline-block;
}

p,
.body {
  font-family: "SofiaPro", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5625em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
}

.button {
  font-family: "Libre Franklin";
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 0px;
  white-space: pre-wrap; // allow newlines
}

@include desktop {

  h1,
  .h1 {
    font-family: "SofiaPro", sans-serif;
    font-size: 2.9375rem;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 2px;
  }

  h2,
  .h2 {
    font-family: "SofiaPro", sans-serif;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 1px;
  }

  .subHeader {
    font-family: "Libre Franklin";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0px;
  }

  .label {
    font-family: "SofiaPro", sans-serif;
    font-size: 1.1875rem;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0px;
  }

  a,
  .link {
    font-family: "Libre Franklin";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0px;
  }

  /* same as h1, but @extend is not allowed across media queries */
  .linkBigOnDesktop {
    font-family: "SofiaPro", sans-serif;
    font-size: 2.9375rem;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 2px;
    display: inline;
  }

  p,
  .body {
    font-family: "SofiaPro", sans-serif;
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.5625em;
    letter-spacing: 0px;
  }

  .button {
    font-family: "Libre Franklin";
    font-size: 1.025rem;
    font-weight: 430;
    line-height: 1em;
    letter-spacing: 2px;
  }
}
