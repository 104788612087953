@font-face {
  font-family: 'Libre Franklin'; // Name to use in your CSS
  src: url("/fonts/LibreFranklin/LibreFranklin-VariableFont_wght.ttf") format("truetype");
  font-style: thin;
  font-weight: 100 900; // For variable fonts
  font-display: swap;
}

@font-face {
  font-family: "SofiaPro";
  src: url("/fonts/SofiaPro/SofiaPro-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "SofiaPro";
  src: url("/fonts/SofiaPro/SofiaPro-SemiBold.ttf");
  font-style: medium;
  font-weight: 700;
  font-display: swap;
}
