// Required by Bootstrap
@import "/node_modules/bootstrap/scss/functions";
@import "properties.scss";
@import "variables.scss";
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";

// Optional by Bootstrap
@import "/node_modules/bootstrap/scss/root";
@import "/node_modules/bootstrap/scss/reboot";
@import "/node_modules/bootstrap/scss/type";
@import "/node_modules/bootstrap/scss/containers";
@import "/node_modules/bootstrap/scss/grid";
@import "/node_modules/bootstrap/scss/bootstrap-utilities.scss";

// Custom
@import "fonts.scss";
@import "typography.scss";
@import "mixins.scss";
@import "cookiebot.scss";

// Layout

html,
body {
  padding: 0;
  margin: 0;
  font-family: SofiaPro, sans-serif;
  font-size: 1rem;
  background: var(--background);
}

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

p {
  margin: 0;
}

.container.full-height {
  height: 100%;

  .row {
    height: 100%;

    [class^="col-"] {
      display: flex;
      flex-direction: column;
    }
  }
}

.anchor {
  display: block;
  position: relative;
  top: -132px;
  visibility: hidden;
}

img {
  max-width: 100%;
}

.relative {
  position: relative;
}

.px-xs {
  padding-left: 24px;
  padding-right: 24px;
}

.noscroll {
  position: fixed;
  overflow-y: scroll;
}

@include desktop {
  .px-xs {
    padding-left: 0px;
    padding-right: 0px;
  }

  .px-md {
    padding-left: 24px;
    padding-right: 24px;
  }

  .col-sm-6 {
    $gird-gutter: 48px;

    &:nth-of-type(odd) {
      padding-right: calc($gird-gutter * 0.5);
    }
    &:nth-of-type(even) {
      padding-left: calc($gird-gutter * 0.5);
    }
  }
}
