/* Some CSS properties are overridden by the CMS (per site), see /components/layout/index.js */

:root {
  /* Palette */
  --primary: #ffc0c0;
  --secondary: #390020;
  --tertiary: #f6f2eb;
  --background: #f6f2eb;
  --link-normal: #756452;
  --link-hover: #390020;
  --link-visited: #7C2E77;
  --shadow-button: url(/images/content/CTA_skugga_moerkroed_2_1_5c617c3c63.png);
  --shadow-ball: url(/images/content/Aknekollen_boll_600px_1e80d5d45c.png);
  --shadow-line: url(/images/content/aknekollen_kant_600x_bda135e77b.png);
}